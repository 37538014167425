import React, { useCallback } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import clsx from 'clsx';
import {
  getReputationScoreColour,
  getReputationScoreLabel,
} from '../../utils/formatting';

type ReputationScoreLegendProps = {
  score: number;
  className: string;
};

const ReputationScoreLegend = ({
  score,
  className = '',
}: ReputationScoreLegendProps): JSX.Element => {
  const intl = useIntl();

  const getReputationScoreDefinition = useCallback(() => {
    // Excellent
    if (score > 80) {
      return intl.formatMessage({
        id: 'marketing-site.reputation-score.legend.excellent.definition',
        description:
          'Explanation of what it means for a reputation score to have the label excellent.',
        defaultMessage:
          'Refers to a company that has achieved a reputation score within the 80–100-point range on a 0 – 100 scale.  This is the highest tier in our normative scale and is shown in dark green.',
      });
    }

    // Strong
    if (score > 70) {
      return intl.formatMessage({
        id: 'marketing-site.reputation-score.legend.strong.definition',
        description:
          'Explanation of what it means for a reputation score to have the label strong.',
        defaultMessage:
          'Refers to a company that has achieved a reputation score within the 70–79-point range on a 0 – 100 scale.  This is the second highest tier in our normative scale and is shown in light green.',
      });
    }

    // Average
    if (score > 60) {
      return intl.formatMessage({
        id: 'marketing-site.reputation-score.legend.average.definition',
        description:
          'Explanation of what it means for a reputation score to have the label average.',
        defaultMessage:
          'Refers to a company that has achieved a reputation score within the 60–69-point range on a 0 – 100 scale.  This is the middle tier in our normative scale and is shown in yellow.',
      });
    }

    // Weak
    if (score > 40) {
      return intl.formatMessage({
        id: 'marketing-site.reputation-score.legend.weak.definition',
        description:
          'Explanation of what it means for a reputation score to have the label weak.',
        defaultMessage:
          'Refers to a company that has achieved a reputation score within the 40–59-point range on a 0 – 100 scale.  This is the second lowest tier in our normative scale and is shown in orange. ',
      });
    }

    // Poor
    return intl.formatMessage({
      id: 'marketing-site.reputation-score.legend.poor.definition',
      description:
        'Explanation of what it means for a reputation score to have the label poor.',
      defaultMessage:
        'Refers to a company that has achieved a reputation score within the 0–39-point range on a 0 – 100 scale.  This is the lowest tier in our normative scale and is shown in red. ',
    });
  }, [score]);

  const getLegendItem = (itemScore, itemLabel, itemClassName = '') => (
    <div
      key={itemScore}
      className={clsx(
        'rounded-full py-2 px-4 xl:px-2 text-center text-white font-bold uppercase mb-2 xl:w-1/5',
        itemClassName,
        getReputationScoreColour(itemScore)
      )}
    >
      <div className="flex items-center justify-between text-xs">
        <div className="pr-2">{getReputationScoreLabel(itemScore)}</div>
        <div className="pl-2">{itemLabel}</div>
      </div>
    </div>
  );

  const legendList = [
    {
      score: 1,
      range: '0-39',
    },
    {
      score: 50,
      range: '40-59',
    },
    {
      score: 66,
      range: '60-69',
    },
    {
      score: 78,
      range: '70-79',
    },
    {
      score: 99,
      range: '80+',
    },
  ];

  return (
    <div className={className}>
      <p className="mb-8 text-gray-900">
        <b>{getReputationScoreLabel(score)}</b> –{' '}
        {getReputationScoreDefinition()}
      </p>
      <div className="flex flex-col xl:flex-row xl:justify-between">
        {legendList.map((item, index) =>
          getLegendItem(item.score, item.range, index === 0 ? '' : 'xl:ml-2')
        )}
      </div>
    </div>
  );
};

export default ReputationScoreLegend;
