import React from 'react';
import { Helmet } from 'react-helmet';

type SEOPageProps = {
  title: string;
  description: string;
  url: string;
  image: string;
};

const SEOPage = ({
  title,
  description,
  url,
  image,
}: SEOPageProps): JSX.Element => (
  <Helmet>
    <meta name="description" content={description} />
    <meta name="image" content={image} />

    {/* OpenGraph */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />

    {/* Twitter */}
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
    <meta property="twitter:url" content={url} />

    <link rel="canonical" href={url} />
  </Helmet>
);

export default SEOPage;
