import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';

import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import ReputationScoreLegend from '../components/ReputationScore/ReputationScoreLegend';
import ReputationScoreLabel from '../components/ReputationScore/ReputationScoreLabel';
import SocialShare from '../components/SocialShare';
import SiteLink from '../components/SiteLink';
import GRT100Badge from '../components/Badges/GRT100Badge';
import SEOPage from '../components/SEO/SEOPage';
import {
  formatReputationScore,
  formatRichText,
  getMovement,
} from '../utils/formatting';
import SchemaOrg from '../components/SEO/SchemaOrg';

const LINK_STYLES =
  'text-rt-steely-grey text-lg font-bold underline hover:text-black focus:text-black';

type CompanyPageProps = {
  pageContext: {
    displayName: string;
    icon: {
      file: {
        url: string;
      };
    };
    lifestyleImage: {
      file: {
        url: string;
      };
    };
    description?: string;
    rankingAnalysis?: {
      raw: string;
    };
    position: number;
    score: string;
    listName: string;
    listSlug: string;
    previousRank?: {
      position: string;
      displayName: string;
      slug: string;
    };
    nextRank?: {
      position: string;
      displayName: string;
      slug: string;
    };
    url: string;
    scoreDelta: number;
    currentYear: number;
    activeReportLink?: {
      url: string;
      label: string;
    };
  };
};

const CompanyPage = ({
  pageContext: {
    displayName,
    icon,
    lifestyleImage,
    description,
    position,
    score,
    listName,
    previousRank,
    nextRank,
    url,
    scoreDelta,
    currentYear,
    rankingAnalysis,
    activeReportLink,
  },
}: CompanyPageProps): JSX.Element => {
  const reputationScore = formatReputationScore(score);
  const pageTitle = `Reptrak | ${displayName} rating`;
  const pageDescription = `Reptrak's company page for ${displayName}, highlighting their Reptrak score and some company information`;
  const rankingsUrl = '/';
  const hasDelta = !!scoreDelta;
  const analysis =
    rankingAnalysis &&
    rankingAnalysis.find(({ year }) => year === currentYear)?.analysis;

  return (
    <Layout title={pageTitle}>
      <div className="p-4 mx-auto max-w-screen-xl">
        <Breadcrumbs
          className="mb-6"
          links={[
            <SiteLink href="https://www.reptrak.com/" isExternal>
              Home
            </SiteLink>,
            <SiteLink href={rankingsUrl}>Rankings</SiteLink>,
          ]}
          activePage={displayName}
        />
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <h1 className="font-semibold text-3xl mr-4">{displayName}</h1>
          <SocialShare
            pageUrl={`${process.env.GATSBY_SITE_DOMAIN}/rankings/${url}`}
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            className="pb-1 my-8"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex flex-row mb-16 md:w-3/4">
            <div className="w-1/4 flex items-center">
              <img src={icon?.file?.url} alt="" />
            </div>
            <div className="w-3/4 flex items-center">
              <img src={lifestyleImage?.file?.url} alt="" />
            </div>
          </div>
          {position <= 100 && (
            <div className="w-2/4 md:w-1/4 mx-auto pb-8">
              <GRT100Badge position={position} year={currentYear} />
            </div>
          )}
        </div>
        {description && (
          <div className="pb-20 leading-loose text-gray-900">{description}</div>
        )}

        {activeReportLink?.url && activeReportLink.label && (
          <SiteLink
            href={activeReportLink.url}
            isExternal
            className="inline-block text-center bg-rt-anchor-blue text-white p-4 text-xl font-bold w-full md:w-auto rounded border-2 border-rt-anchor-blue focus:bg-white hover:bg-white focus:text-rt-anchor-blue hover:text-rt-anchor-blue mb-20"
          >
            {activeReportLink.label}
          </SiteLink>
        )}

        <div className="bg-rt-white-smoke flex flex-col p-8 border border-rt-grey-smoke">
          <h2 className="mx-auto text-2xl font-semibold sm:mb-8 sm:mx-0">
            Reputation Score
          </h2>
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col w-100 sm:w-1/3 xl:w-1/4 items-center sm:justify-start justify-center py-8 border-b sm:border-b-0 sm:border-r border-rt-grey-smoke">
              <div>
                <span className="text-7xl text-rt-pale-sky font-normal pb-2">
                  {reputationScore}
                </span>
                <ReputationScoreLabel
                  score={reputationScore}
                  className="items-center justify-center my-2"
                />
              </div>
              {hasDelta && (
                <div className="flex flex-row my-2">
                  {getMovement(0, scoreDelta)}
                  <span className="pl-4 text-rt-steely-grey font-semibold">
                    {scoreDelta.toFixed(1)} vs. {currentYear - 1}
                  </span>
                </div>
              )}
            </div>
            <ReputationScoreLegend
              score={reputationScore}
              className="mt-4 p-4 sm:w-2/3 xl:w-3/4"
            />
          </div>
          {analysis && (
            <div className="border-t border-rt-grey-smoke pt-8 mt-8">
              <div className="font-semibold text-2xl mb-4">
                Reputation Analysis
              </div>
              {formatRichText(analysis.raw)}
            </div>
          )}
        </div>

        <div className="flex flex-col mt-20 mb-40 lg:my-20">
          <SiteLink
            className={clsx('self-center', 'mb-10', 'lg:hidden', LINK_STYLES)}
            href={rankingsUrl}
          >
            View the {listName}
          </SiteLink>
          <div className="flex justify-between">
            <div>
              {previousRank && (
                <SiteLink
                  href={`/company/${previousRank.slug}`}
                  className={LINK_STYLES}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faAngleLeft}
                    size="lg"
                  />
                  #{previousRank.position} {previousRank.displayName}
                </SiteLink>
              )}
            </div>
            <SiteLink
              className={clsx('hidden', 'lg:inline-block', LINK_STYLES)}
              href={rankingsUrl}
            >
              View the {listName}
            </SiteLink>
            <div>
              {nextRank && (
                <SiteLink
                  href={`/company/${nextRank.slug}`}
                  className={LINK_STYLES}
                >
                  #{nextRank.position} {nextRank.displayName}
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faAngleRight}
                    size="lg"
                  />
                </SiteLink>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* SEO Stuff */}
      <SEOPage
        title={pageTitle}
        description={pageDescription}
        image={icon?.file?.url}
        url={url}
      />
      <SchemaOrg
        description={pageDescription}
        logo={icon?.file?.url}
        url={url}
        name={displayName}
        type="Organization"
      />
    </Layout>
  );
};

export default CompanyPage;
