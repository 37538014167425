import React from 'react';
import { Helmet } from 'react-helmet';

type SchemaOrgProps = {
  description: string;
  url: string;
  logo: string;
  name: string;
  type: string;
};

const SchemaOrg = ({
  description,
  url,
  logo,
  type,
  name,
}: SchemaOrgProps): JSX.Element => {
  const schemaObject = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': type,
    name,
    description,
    logo,
    url,
  });
  return (
    <Helmet>
      <script type="application/ld+json">{schemaObject}</script>
    </Helmet>
  );
};

export default SchemaOrg;
